import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Grid, Box, Button, Text, Image } from "theme-ui"

const HomepageHero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHomepageHero {
          edges {
            node {
              title
              body {
                body
              }
              buttonLabel
              url
              layout
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  
  return (
    <>
      {data.allContentfulHomepageHero.edges.map(edge => {
        return (
          <>
            {edge.node.layout === "Small" &&
              <Grid variant="base" gap={[4, 6, 8]} columns={[1, 1, "2fr 3fr"]} sx={{ px: [2, 4, 8], mb: [7, 8], alignItems: "center" }}>
                <Box sx={{ textAlign: ["center", "center", "left"], mt: [4, 4, 0] }}>
                  <Text as="h1" variant="title01" sx={{ mb: 2 }}>{edge.node.title}</Text>
                  <Text as="p" variant="body" sx={{ fontSize: 'md', mb: 4 }}>{edge.node.body.body}</Text>
                  <Link to={edge.node.url}>
                    <Button variant="secondary">{edge.node.buttonLabel}</Button>
                  </Link>
                </Box>
                
                <Image src={edge.node.image.file.url} alt={edge.node.title} sx={{ maxWidth: [400, 400, 600], mx: "auto" }} />
              </Grid>
            }

            {edge.node.layout === "Large" &&
              <Grid
                variant="base"
                gap={[4, 6, 8]}
                columns={[1, 1, "2fr 3fr"]}
                sx={{
                  py: [0, 0, 8],
                  mt: [-5, 0, "-163px"],
                  mb: [7, 8],
                  alignItems: "center",
                  backgroundImage: ["none", "none", `url(${edge.node.image.file.url})`],
                  backgroundSize: ["80%", "75%"],
                  backgroundPosition: ["center top", "right 0%", "right 15%"],
                  backgroundRepeat: "no-repeat",
                  borderBottom: ["none", "none", "1px solid"],
                  overflowX: "hidden"
                }}
              >
                <Image
                  src={edge.node.image.file.url}
                  alt={edge.node.title}
                  sx={{
                    display: ["block", "block", "none"],
                    maxWidth: [400, 400, 600],
                    width: "100%",
                    mx: "auto"
                  }}
                />
                
                <Box
                  sx={{
                    bg: ["background", "background", "transparent"],
                    textAlign: ["center", "center", "left"],
                    mt: [-160, 0, 8],
                    mb: [0, 0, 8],
                    pt: [4, 0, 8],
                    borderTop: ["1px solid", "none", "none"]
                  }}
                >
                  <Text as="h1" variant="title01" sx={{ fontSize: ["xl", "xxl", "xxxl"], mb: 2, fontFamily: "display" }}>{edge.node.title}</Text>
                  <Box sx={{ mb: 4 }}>
                    <Text as="p" variant="bodyLarge" sx={{ mb: 4, bg: "background", display: "inline", borderRadius: 12, pr: "4px" }}>{edge.node.body.body}</Text>
                  </Box>
                  <Link to={edge.node.url}>
                    <Button variant="secondary">{edge.node.buttonLabel}</Button>
                  </Link>
                </Box>
              </Grid>
            }
          </>
        )
      })}
    </>
  )
}

export default HomepageHero