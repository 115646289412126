import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Grid, Box, Text } from "theme-ui"
import LinkCard from "./LinkCard"

const HomepageFeaturedProducts = () => { 
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulProduct {
          edges {
            node {
              name
              price
              excerpt
              slug
              status
              category
              images {
                file {
                  url
                }
              }
              homepage
            }
          }
        }
      }
    `
  )
  
  return (
    <>
      <Grid variant="base" gap={[4, 6]} columns={[1, "1fr 2fr"]} sx={{ mt: [7, 8], pt: [5, 6] }}>
        <Box sx={{ pr: [0, 4], pt: [5, 3] }}>
          <Text as="h2" variant="title02">Wondrous treasures</Text>
          <Text as="p" variant="body">From our Game Book to our card decks and fun accessories, we've got everything you need to start your great adventure. Check out our <Link to="/store"><Text as="span" variant="link">store</Text></Link> to get started.</Text>
        </Box>

        <Grid columns={[1, 1, 1, 2]} sx={{ gridGap: [4, 4, 4, 6] }}>
          {data.allContentfulProduct.edges.map(edge => {
            return (
              <>
                {edge.node.homepage === true &&
                  <LinkCard
                    title={edge.node.name}
                    status={edge.node.status}
                    price={edge.node.price}
                    body={edge.node.excerpt}
                    url={`/store/${edge.node.slug}/`}
                    image={
                      <Box
                        sx={{ 
                          backgroundImage: `url(${edge.node.images[0].file.url})`, 
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          height: [280, 400, 500],
                          border: "2px solid",
                          mb: 4
                        }} 
                      />
                    }
                    buttonLabel={edge.node.status === "Available" ? `$${edge.node.price}` : edge.node.status}
                  />
                }
              </>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default HomepageFeaturedProducts