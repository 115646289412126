import React from "react"
import { Box, Text } from "theme-ui"

export default function Blockquote({ quote, cite, ...props }) {
  return (
    <Box as="blockquote" {...props}>
      <Text as="p" variant="display" sx={{ mb: 0 }}>“</Text>
      <Text as="p" variant="title01" sx={{ mb: 0 }}>{quote}</Text>
      {cite && <Box as="footer" sx={{ maxWidth: "none", mt: 3 }}>
        <Text variant="label">— {cite}</Text>
      </Box>}
    </Box>
  )
}