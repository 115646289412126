import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Grid, Box, Text, Button, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomepageHero from "../components/HomepageHero"
import HomepageFeaturedProducts from "../components/HomepageFeaturedProducts"
import LinkCard from "../components/LinkCard"
import Quote from "../components/Quote"

import wizardRightImage from "../images/spots/wizard-right.png"
import educationCicadaImage from "../images/spots/education-cicada.png"
import professorPrimImage from "../images/spots/professor-prim.png"
import questCreatorsLicenseAndResourceFile from "../downloads/quest-creators-license-and-resource.pdf"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHomepageUpdate {
          edges {
            node {
              tag
              headline
              description {
                description
              }
              buttonLabel
              url
            }
          }
        }
      }
    `
  )

  return (
    <Layout showNewsletter>
      <SEO title="Home" />
      
      <HomepageHero />
      
      <Grid variant="base" columns={[1, 1, 3]} sx={{ my: [7, 8], gridGap: 6 }}>
        {data.allContentfulHomepageUpdate.edges.map(edge => {
          return (
            <LinkCard
              key={edge.node.tag}
              label={edge.node.tag}
              title={edge.node.headline}
              body={edge.node.description.description}
              buttonLabel={edge.node.buttonLabel}
              url={edge.node.url} />
          )
        })}
      </Grid>
      
      <Grid variant="base" gap={[4, 6, 8]} columns={[1, 1, "2fr 3fr"]} sx={{ pt: [4, 6], bg: "primary10", alignItems: "center", overflowX: "visible" }}>
        <Box sx={{ pb: [4, 6] }}>
          <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>New to Quest?</Text>
          <Text as="p" variant="body" sx={{ mb: 4 }}>Quest is a thrilling roleplaying game about amazing people in a world of magic and danger. Everyone is welcome, and you can be whoever you want to be. The possibilities are endless.</Text>
          <Link to="/about">
            <Button variant="secondary" sx={{ "&:hover": { bg: "primary20" }, "&:focus": { bg: "primary20" } }}>Learn More</Button>
          </Link>
        </Box>
        
        <Image src={wizardRightImage} alt="Wizard" sx={{ mb: -7, width: "100%" }} />
      </Grid>
      
      <HomepageFeaturedProducts />

      <Grid variant="base" columns={[1, 2, "1fr 2fr"]} sx={{ bg: "grayXLight", py: 5, my: [7, 8], alignItems: "center" }}>
        <Box>
          <Text as="h2" variant="title02">Listen & learn</Text>
          <Text as="p" variant="body">Join our narrator, The Guide, and two adventurers as they explore the first chapter of our Game Book.</Text>
        </Box>
        
        <Box sx={{ width: "100%", maxWidth: 768, justifySelf: "end" }}>
          <iframe title="Rules" src="https://anchor.fm/tc-sottek/embed/episodes/Learn-to-play-Quest-en9n0f" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
        </Box>
      </Grid>

      <Grid variant="base" columns={[1, 2]} sx={{ gridGap: [4, 6, 8] }}>
        <Box>
          <Image src={professorPrimImage} alt="Professor Prim" sx={{ maxHeight: [400, 500, 600], mx: "auto", mb: 4 }} />
          
          <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>Create for Quest</Text>
          <Text as="p" variant="body" sx={{ mb: 4 }}>Anyone can use our free Community Creators Resource to make their own content for Quest.</Text>
          
          <a href={questCreatorsLicenseAndResourceFile} download>
            <Button variant="secondary">Download Resource (PDF)</Button>
          </a>
        </Box>

        <Box>
          <Image src={educationCicadaImage} alt="Education Cicada" sx={{ maxHeight: [400, 500, 600], mx: "auto", mb: 4 }} />

          <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>Quest for Education</Text>
          <Text as="p" variant="body" sx={{ mb: 4 }}>Teachers and counselors across the world are using Quest with their students and patients. Get the game now.</Text>
          
          <Link to="/store/digital-edition/">
            <Button variant="secondary">Get Quest</Button>
          </Link>
        </Box>
      </Grid>

      <Grid variant="base" columns={[1, "3fr 1fr"]} sx={{ mt: [7, 8], mb: 2, py: 3 }}>
        <Quote
          quote="Quest has a good shot at becoming the definitive RPG for first-time players"
          cite="Dicebreaker"
          sx={{
            p: [3, 5],
            bg: "background",
            border: "1px solid",
            borderColor: "text",
            boxShadow: theme => `12px 12px 0 ${theme.colors.grayLight}`
          }}
        />
      </Grid>

      <Grid variant="base" columns={[1, "1fr 3fr"]} sx={{ mb: 2, py: 3 }}>
        <Box />
        
        <Quote
          quote="It’s full of thoughtful, welcoming, evocative details"
          cite="Rob Dubbin, The Late Show & The Colbert Report"
          sx={{
            p: [3, 5],
            bg: "background",
            border: "1px solid",
            borderColor: "text",
            boxShadow: theme => `12px 12px 0 ${theme.colors.grayLight}`
          }}
        />
      </Grid>
      
      <Grid variant="base" columns={[1, "3fr 1fr"]} sx={{ py: 3 }}>
        <Quote
          quote="I love the ways you can fail"
          cite="Pendleton Ward, creator of Adventure Time"
          sx={{
            p: [3, 5],
            bg: "background",
            border: "1px solid",
            borderColor: "text",
            boxShadow: theme => `12px 12px 0 ${theme.colors.grayLight}`
          }}
        />
      </Grid>
    </Layout>
  )
}

export default IndexPage